<template>
  <v-app>
    <v-app-bar
        app
        color="#3397C1"
        dark
    >
      <div class="d-flex align-center">
        <h2>Tim Seidel | Homepage</h2>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <main-content/>
    </v-main>

    <v-footer >
      <v-col
          class="text-center"
          cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Tim Seidel</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import MainContent from './components/Content';

//Colors: 3397C1 | 3C3C3C
export default {
  name: 'App',
  components: {
    MainContent,
  },
  created() {
    document.title = "Tim Seidel"
  },
};
</script>

<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="hptext display-2 mb-3 mt-3">
          Willkommen
        </h1>
        <p class="hptext subheading font-weight-regular">
          Hier gibt es aktuell noch keinen wundervollen Content,
          <br>aber hier ist mein
          <a
            href="https://github.com/tim-seidel"
            target="_blank"
          >Github-Link</a>.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Content',

    data: () => ({
    }),
  }
</script>

<style scoped>
  .hptext {
    color: #3c3c3c;
  }
</style>
